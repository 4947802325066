<template>
  <v-row
    no-gutters
  >
    <v-col
      :cols="colsLeft"
    >
      <v-card
        flat
        outlined
        :height="workbenchHeight / 1.95 + 'px'"
        style="overflow-y: auto; overflow-x: hidden;"
      >
        <v-toolbar
          dense
          flat
          height="45"
          color="lightBackground"
          style="position: sticky; top: 0px; z-index: 1;"
        >
          <v-subheader
            class="ml-n7"
          >
            {{ account.accountType === 'lawyer' ? $t('cases|templates') : $t('documents|documents') }}
          </v-subheader>
          <v-spacer />
          <v-btn
            v-if="account.accountType === 'lawyer' && $vuetify.breakpoint.width > 1500"
            x-small
            rounded
            color="primary"
            @click="addWorkbenchTemplate"
          >
            <v-icon
              x-small
              class="mr-1"
            >
              mdi-plus
            </v-icon>
            {{ $t('actions|add_new_template') }}
          </v-btn>
          <v-btn
            v-if="account.accountType === 'lawyer' && $vuetify.breakpoint.width <= 1500"
            x-small
            fab
            color="primary"
            @click="addWorkbenchTemplate"
          >
            <v-icon
              x-small
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row
            v-if="account.accountType === 'lawyer'"
            align="center"
          >
            <div
              v-for="temp in workbenchDrawerOverview.templates"
              :key="temp._id"
              class="mx-2 my-4"
            >
              <TemplateCardCases
                :template="temp"
                :workbench="workbenchDrawerOverview"
                :case-data="caseDataDrawerOverview"
                :from-drawer="true"
                @update-template-name="updateTemplateName(workbenchDrawerOverview, temp)"
                @share-with-client="shareWithClient(workbenchDrawerOverview, temp)"
                @stop-sharing-with-client="stopSharingWithClient(workbenchDrawerOverview, temp)"
                @delete="deleteWorkbenchTemplate(workbenchDrawerOverview, temp)"
                @close-drawer="$emit('close-drawer')"
              />
            </div>
          </v-row>
          <v-row
            v-if="account.accountType !== 'lawyer'"
            align="center"
          >
            <div
              v-for="temp in workbenchDrawerOverview.templates"
              :key="temp._id"
              class="mx-2 my-4"
            >
              <TemplateCardCases
                v-if="temp.sharedWithClient"
                :template="temp"
                :workbench="workbenchDrawerOverview"
                :case-data="caseDataDrawerOverview"
                :from-drawer="true"
                @share-with-client="shareWithClient(workbenchDrawerOverview, temp)"
                @stop-sharing-with-client="stopSharingWithClient(workbenchDrawerOverview, temp)"
                @delete="deleteWorkbenchTemplate(workbenchDrawerOverview, temp)"
                @close-drawer="$emit('close-drawer')"
              />
            </div>
          </v-row>
          <div
            v-if="!workbenchDrawerOverview.templates.length"
            class="center"
            style="font-size: 15px; color: black;"
          >
            {{ $t('expressions|no_actual_workbench_templates') }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-if="account.accountType === 'lawyer'"
      :cols="colsRight"
    >
      <v-card
        flat
        outlined
        class="ml-1"
        :height="workbenchHeight / 1.95 + 'px'"
      >
        <v-toolbar
          dense
          flat
          height="45"
          color="lightBackground"
          style="position: sticky; top: 0px; z-index: 1000;"
        >
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-subheader
                class="ml-n4"
                v-bind="attrs"
                v-on="on"
              >
                {{ workbenchDocumentsMenuItems[selectedView] }}
                <v-icon>
                  mdi-menu-down
                </v-icon>
              </v-subheader>
            </template>
            <v-list
              v-model="selectedView"
            >
              <v-list-item
                v-for="(item, index) in workbenchDocumentsMenuItems"
                :key="index"
                @click="setView(index)"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <template>
            <div>
              <v-dialog
                v-model="dialog"
                width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="info"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-variant
                  </v-icon>
                </template>

                <v-card>
                  <v-card-title class="text-h6 grey lighten-2">
                    {{ $t('mixed|workbench_documents') }}
                  </v-card-title>

                  <v-card-text
                    v-if="selectedView === 0"
                    class="mt-4"
                  >
                    {{ $t('expressions|assigning_documents_info') }}
                  </v-card-text>
                  <v-card-text
                    v-else
                    class="mt-4"
                  >
                    {{ $t('expressions|required_document_info') }}
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      text
                      @click="dialog = false"
                    >
                      {{ $t('actions|close') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </template>
          <v-spacer />
          <v-btn
            v-if="workbench && selectedView === 0 && $vuetify.breakpoint.width > 1500"
            x-small
            rounded
            color="primary"
            @click="assignWorkbenchDocument(workbench)"
          >
            <v-icon
              x-small
              class="mr-2"
            >
              mdi-link
            </v-icon>
            {{ $t('actions|assign_document') }}
          </v-btn>
          <v-btn
            v-if="workbench && selectedView === 0 && $vuetify.breakpoint.width <= 1500"
            x-small
            fab
            color="primary"
            @click="assignWorkbenchDocument(workbench)"
          >
            <v-icon
              x-small
            >
              mdi-link
            </v-icon>
          </v-btn>
          <v-btn
            v-if="workbench.requiredDocuments && selectedView === 1 && $vuetify.breakpoint.width > 1500"
            x-small
            rounded
            color="primary"
            @click="openRequiredDocumentDialog()"
          >
            <v-icon
              x-small
              class="mr-1"
            >
              mdi-link
            </v-icon>
            {{ $t('actions|add_a_required_document') }}
          </v-btn>
          <v-btn
            v-if="workbench.requiredDocuments && selectedView === 1 && $vuetify.breakpoint.width <= 1500"
            x-small
            fab
            color="primary"
            @click="openRequiredDocumentDialog()"
          >
            <v-icon
              x-small
            >
              mdi-link
            </v-icon>
          </v-btn>
        </v-toolbar>

        <!-- Workbench documents -->
        <div
          v-if="selectedView === 0"
          style="width: 100%;"
        >
          <document-list
            v-if="selectedView === 0"
            :workbench-documents="workbenchDrawerOverview.assignedDocuments"
            :toolbar="null"
            :is-flat="true"
            :in-case="true"
            :right-panel-workbench="true"
            :width="'100%'"
            class="flex-grow-1"
            @newWorkbenchDocument="onNewWorkbenchDocument"
            @click="openDocument"
            @delete="(doc) => removeWorkbenchDocument({ workbenchDrawerOverview, file: doc })"
          />
        </div>
        <div
          v-if="selectedView === 0 && !workbenchDrawerOverview.assignedDocuments.length"
          class="center"
          style="font-size: 15px;"
        >
          {{ $t('expressions|no_actual_workbench_documents') }}
        </div>

        <!-- Required workbench documents -->
        <v-list
          v-if="workbenchDrawerOverview && workbenchDrawerOverview.requiredDocuments && selectedView === 1"
          :value="true"
          dense
          class="mt-1"
          :style="`height: ${($vuetify.breakpoint.height - 560).toString() + 'px'}; overflow-y: auto; ${dragAreaRequiredDocs ? 'outline-style: dashed; margin-left: 10px; margin-right: 10px;' : ''}`"
        >
          <draggable
            :list="workbenchDrawerOverview.requiredDocuments"
            group="wbReqDocs"
            class="dragArea list-group"
            ghost-class="ghost"
            @start="onStart"
            @end="onEnd"
            @change="onChangeReqDocs"
          >
            <div
              v-for="(suggestion, index) in workbenchDrawerOverview.requiredDocuments"
              :key="index"
            >
              <v-card
                flat
                rounded
                outlined
                height="60px"
                class="my-4 mx-2 d-flex rounded-lg"
              >
                <v-row
                  no-gutters
                >
                  <v-col
                    sm="2"
                    md="1"
                    lg="1"
                    xs="2"
                  >
                    <v-card
                      class="rounded-r-0"
                      flat
                      height="100%"
                      color="lightBackground"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            class="handle-icon mt-3 ml-2"
                            style="cursor: grab;"
                            size="34"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ icons.drag }}
                          </v-icon>
                        </template>
                        <span>{{ $t('actions|drag_document_within_workbench') }}</span>
                      </v-tooltip>
                    </v-card>
                  </v-col>
                  <v-col cols="1">
                    <v-icon
                      color="primary"
                      class="ml-2 mt-4"
                    >
                      mdi-file-outline
                    </v-icon>
                  </v-col>
                  <v-col
                    sm="8"
                    md="9"
                    lg="9"
                  >
                    <v-card
                      dark
                      flat
                      height="100%"
                      class="rounded-l-0"
                    >
                      <div
                        class="grey--text text--darken-2 ml-3 mt-2"
                        style="font-size: 13px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
                      >
                        {{ $t('documents|required_document') }}
                      </div>
                      <div
                        class="primary--text ml-3 mt-1"
                        style="font-size: 13px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
                      >
                        {{ translateFileInputField(suggestion) }}
                      </div>
                    </v-card>
                  </v-col>
                  <v-col
                    sm="2"
                    md="1"
                    lg="1"
                  >
                    <v-card
                      class="rounded-l-0"
                      flat
                      height="100%"
                      color="lightBackground"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            :color="checkIfSuggestionTypeAvailableInDocuments(suggestion, workbenchDrawerOverview) ? 'success' : 'grey'"
                            v-bind="attrs"
                            size="20"
                            class="ml-2 mt-1"
                            v-on="on"
                          >
                            mdi-check-circle
                          </v-icon>
                        </template>
                        <span>
                          {{ checkIfSuggestionTypeAvailableInDocuments(suggestion, workbenchDrawerOverview) ? $t('expressions|document_type_available_in_wb') : $t('expressions|document_type_not_uploaded') }}
                        </span>
                      </v-tooltip>
                      <v-menu
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            icon
                            size="20"
                            class="ml-2 mt-1"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ icons.cog }}
                          </v-icon>
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-icon
                              color="primary"
                              class="mr-2"
                            >
                              mdi-link-off
                            </v-icon>
                            <v-list-item-title
                              style="cursor: pointer;"
                              @click="removeRequiredDocument(suggestion)"
                            >
                              {{ $t('actions|remove') }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </draggable>
          <div
            v-if="!workbenchDrawerOverview.requiredDocuments.length && selectedView === 1"
            class="center"
            style="font-size: 15px"
          >
            {{ $t('expressions|no_actual_required_workbench_documents') }}
          </div>
        </v-list>
      </v-card>
    </v-col>
    <v-col
      v-if="account.accountType === 'individual' || account.accountType === 'company'"
      :cols="colsRight"
    >
      <v-card
        flat
        color="#fbfbfb"
        class="pa-2 fill-height"
        style="border-left-style: outset; border-left-color: #f2f2f2; border-left-width: 7px;"
      >
        <v-row align="center">
          <v-card-title style="font-size:1em">
            {{ $t('questionnaire|questionnaires') }}
          </v-card-title>
        </v-row>
        <div
          v-for="temp in workbenchDrawerOverview.templates"
          :key="temp._id"
          class="mx-2 my-4"
        >
          <QuestionnaireClientCard
            v-if="temp.questionnaire.sharedWithClient"
            :template="temp"
            :workbench="workbenchDrawerOverview"
            @open-answer-questionnaire="openAnswerDialog"
          />
        </div>
        <QuestionnaireAnswerDialog
          ref="QuestionnaireAnswerDialog"
          @updateQuestionnaire="updateQuestionnaire"
        />
      </v-card>
    </v-col>
    <FileView ref="fileView" />
    <dialog-base
      v-model="addNewRequiredDocDialog"
      :toolbar-active="toolbarActive"
      :style-header="'position: sticky; top: 0px; z-index: 1;'"
      :style-text-field="'position: sticky; top: 63px; z-index: 1; background-color: white;'"
      :actions-style="'position: sticky; bottom: 0px; z-index: 1; background-color: white;'"
      @searching-field="search"
      @dialog-closed="resetRequiredDocDialog"
    >
      <template
        v-slot:header
      >
        {{ $t('actions|select_new_required_document') }}
      </template>
      <template>
        <v-expand-transition>
          <v-list v-show="!customField">
            <v-list-item-group
              v-model="activeReqDocuments"
              multiple
              dense
            >
              <template v-for="(item, i) in reqDocumentFields">
                <v-divider
                  v-if="i !== 0"
                  :key="`divider-${i}`"
                />

                <v-list-item
                  :key="`item-${i}`"
                  :value="item"
                  active-class="deep-purple--text accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-icon>
                      <v-icon>
                        mdi-file-hidden
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title
                        style="white-space: normal;"
                      >
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="deep-purple accent-4"
                      />
                      <v-icon
                        v-if="item.name === 'Custom'"
                      >
                        {{ icons.cog }}
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-expand-transition>
        <v-text-field
          v-show="customField"
          v-model="customFieldInput"
          outlined
          dense
          :label="$t('actions|add_document_type')"
          class="mt-4"
        />
      </template>
      <template
        v-slot:actions
      >
        <v-btn
          v-if="customField"
          text
          small
          min-width="150"
          @click="customField = false; customFieldInput = null; toolbarActive = true"
        >
          {{ $t('actions|back') }}
        </v-btn>
        <v-spacer />
        <!--:disabled="disabledRule"-->
        <v-btn
          rounded
          dense
          small
          color="primary"
          min-width="150"
          @click="customField ? addCustomField(customFieldInput) : addFieldsToWorkbench(activeReqDocuments)"
        >
          {{ $t('actions|submit') }}
        </v-btn>
      </template>
    </dialog-base>
  </v-row>
</template>

<script>
import generalMixin from '@/utils/generalMixin'
import caseWorkbenchMixin from './CaseMixins/caseWorkbenchMixin'

export default {
  mixins: [generalMixin, caseWorkbenchMixin],
  props: {
    caseDataDrawerOverview: {
      type: Object,
      default: null
    },
    workbenchDrawerOverview: {
      type: Object,
      default: null
    },
    workbenchHeight: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ghost {
    background-color: #d7d7d7;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
  }
</style>
